import { Box, css } from "@storyofams/react-ui";

import { Article } from "~components/common/Layout/SearchCards/Article";
import { Button, Heading, Link } from "../../components";
import { usePostHog } from "posthog-js/react";

interface ResultsProps {
  items: any;
  recipeItems: any;
}

export const BlogResults = ({ items, recipeItems }: ResultsProps) => {
  const posthog = usePostHog();
  return (
    <Box>
      <Box display={["block", "block", "flex"]}>
        {items && !!items.length && (
          <Box width="100%" mb={[3, 3, 0]} overflow="hidden">
            <BlogsHeader title={"Blogs"} />
            {items.slice(0, 3).map((item, i) => (
              <Box
                border="1px solid grey200"
                borderRadius={"md"}
                my={1.5}
                key={i}
                // onClick={() => posthog.capture("Clicked on blog search page")}
              >
                <Article article={item} />
              </Box>
            ))}
            <Button
              width={["100%", "47%"]}
              variant="secondary"
              as="a"
              href="/blog"
              minHeight={"50px"}
              py="0"
            >
              Toon alle blogs
            </Button>
          </Box>
        )}
        {recipeItems && !!recipeItems.length && (
          <Box
            width="100%"
            mb={[3, 3, 0]}
            ml={[0, 0, items && items.length && 2]}
            overflow="hidden"
          >
            <BlogsHeader title={"Recepten"} />
            {recipeItems.slice(0, 3).map((item, i) => (
              <Box
                border="1px solid grey200"
                borderRadius={"md"}
                my={1.5}
                key={i}
                // onClick={() => posthog.capture("Clicked on recipe search page")}
              >
                <Article article={item} />
              </Box>
            ))}
            <Button
              minHeight={"50px"}
              py="0"
              width={["100%", "47%"]}
              variant="secondary"
              as="a"
              href="/recipes"
            >
              Toon alle recepten
            </Button>
          </Box>
        )}
      </Box>
    </Box>
  );
};
const BlogsHeader = ({ title }): JSX.Element => {
  return (
    <Heading
      pb={0.5}
      fontWeight={500}
      fontSize={2.5}
      as="h3"
      letterSpacing="-0.02em"
      borderBottom="1px solid grey200"
      css={css({
        textTransform: ["unset", "uppercase"],
      })}
    >
      {title}
    </Heading>
  );
};
const BlogsName = ({ name, link }): JSX.Element => {
  return (
    <Link href={link}>
      <Box
        mt={"13px"}
        cursor="pointer"
        maxWidth={["none", "none", "580px"]}
        overflow="hidden"
        whiteSpace="nowrap"
        fontSize={["16px", "18px"]}
        css={{ textOverflow: "ellipsis" }}
      >
        {name}
      </Box>
    </Link>
  );
};
